@import url('https://fonts.googleapis.com/css2?family=Sour+Gummy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moul&display=swap');


.sour-gummy-gc {
  font-family: "Sour Gummy", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings:
    "wdth" 300;

}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('background.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    color: white;
    text-align: center;
    padding: 0 20px;
    position: relative;
}

.hero-content {
    max-width: 600px;
    z-index: 1;
}

.hero-title {
    font-size: 3em;
    font-family: 'Moul', sans-serif;
    margin-bottom: 1rem;
        /* Dark gray background */

    /* This is a medium gray color, which is between white and black */
}

.hero-description {
    font-size: 2em;
    margin-bottom: 2rem;
     background-color: #333333;
}

.hero-btn {
    padding: 12px 24px;
    font-size: 1em;
    border: none;
    border-radius: 25px;
    background-color: #ffffff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
}

.hero-btn:hover {
    background-color: #f0f0f0;
}

.down-arrow {
    position: absolute;
    bottom: 20px;
    font-size: 2rem;
    animation: bounce 1.5s infinite;
    cursor: pointer;
    color: #fff;
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(10px); }
}
