/* VideoSection.css */

.video-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    width: auto%;
}

.video-section video {
    width: 60%; /* Set to 60% of the screen width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for a stylish look */
}




.video-section h2 {
    margin-bottom: 20px;
    display: grid;

    font-size: 2.5em;
    margin-bottom: 1rem;
    font-family: Arial, sans-serif;

}

