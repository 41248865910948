.contact-section {
    background-color: rgba(240, 240, 240, 0.9); /* Light background */
    color: #333; /* Darker text for better contrast */
    padding: 2rem 1rem; /* Padding for spacing */
    text-align: center; /* Center text */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin: 2rem auto; /* Center the section */
    max-width: 600px; /* Max width for readability */
}

.contact-section h2 {
    font-size: 2rem; /* Heading size */
    margin-bottom: 1rem; /* Spacing below heading */
}

.contact-section p {
    font-size: 1rem; /* Paragraph size */
    margin-bottom: 2rem; /* Spacing below paragraph */
}

.contact-form {
    display: flex; /* Flexbox for alignment */
    flex-direction: column; /* Column layout for form */
    align-items: center; /* Center form elements */
    gap: 1rem; /* Space between input elements */
}

.contact-input {
    padding: 0.5rem; /* Padding for input */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    width: 100%; /* Full width for input */
    max-width: 400px; /* Max width for input */
    font-size: 1rem; /* Font size for input */
}

.btn {
    background-color: #4CAF50; /* Green button */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    padding: 0.5rem 1rem; /* Padding for button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1rem; /* Font size for button */
    transition: background-color 0.3s; /* Smooth transition */
}

.btn:hover {
    background-color: #45a049; /* Darker green on hover */
}

.footer {
    margin-top: 2rem; /* Space above footer */
    font-size: 0.9rem; /* Smaller font size */
    color: #666; /* Lighter text for footer */
}

.contact-section p {
    font-size: 1rem;
    margin-top: 1rem;
}

.contact-section .success {
    color: green;
}

.contact-section .error {
    color: red;
}
