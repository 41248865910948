body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    background-image: url('bkg.JPG');

}

.content {
    text-align: center;
    color: white;
    max-width: 800px;
    margin: 0 auto;
}

.mission {
    font-size: 2rem;
    margin: 0 0 1rem;
}

.prejoin-btn {
    display: inline-block;          /* Allow padding to apply */
    padding: 10px 20px;           /* Adjust padding for size */
    background-color: white;      /* White background */
    border-radius: 25px;          /* Make it round */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
    text-decoration: none;         /* Remove underline */
    color: #333;                  /* Text color */
    transition: background-color 0.3s, transform 0.2s;
}

.down-arrow {
    margin: 2rem auto; /* Centers the arrow horizontally and adds top margin */
    display: block; /* Ensures it behaves as a block element */
    width: 30px; /* Increased width */
    height: 30px; /* Increased height */
    background-image: url('double-arrow-down.svg');
    background-size: contain;
    background-color: white; /* Make the arrow icon white */
    mask-image: url('arrow.svg'); /* Ensures the image uses the color */
    mask-size: contain;
    animation: bounce 1.5s infinite;
    /* Optional: Add additional centering */
}

.arrow-container {
    display: flex;
    justify-content: center; /* Centers the arrow horizontally */
}






.contact-link {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;          /* Allow padding to apply */
    padding: 10px 20px;           /* Adjust padding for size */
    background-color: white;      /* White background */
    border-radius: 25px;          /* Make it round */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
    text-decoration: none;         /* Remove underline */
    color: #333;                  /* Text color */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

.contact-link:hover {
    background-color: #f0f0f0;    /* Slightly darker on hover */
    transform: scale(1.05);       /* Scale up on hover */
}
