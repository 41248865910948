/* General Reset */
.page-reset {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.landing-page-body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    background-color: #f4f4f9;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

/* Landing Page Container */
.landing-page {
    width: 100%;
    max-width: 800px; /* Constrain the width for a centered, minimalist feel */
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Header Section */
.landing-header {
    background-color: #2c3e50; /* Darker, muted color for sophistication */
    color: white;
    padding: 30px 20px;
    border-radius: 12px 12px 0 0; /* Rounded corners on the top */
}

.landing-header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.landing-header p {
    font-size: 1.2em;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.4;
}

/* Mission Section */
.mission-section {
    padding: 40px 20px;
    margin-top: 20px;
}

.mission-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #2c3e50;
}

.mission-section p {
    font-size: 1.15em;
    color: #555;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.5;
}

/* Email Section */
.email-section {
    padding: 40px 20px;
    background-color: #ecf0f1;
    border-radius: 12px;
    margin-top: 20px;
}

.email-section h2 {
    font-size: 1.75em;
    margin-bottom: 15px;
    color: #2c3e50;
}

.email-section p {
    font-size: 1.15em;
    color: #555;
    max-width: 600px;
    margin: 0 auto 20px;
    line-height: 1.4;
}

.email-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-input {
    padding: 12px;
    width: 100%;
    max-width: 300px; /* Restrict width for better layout */
    border-radius: 25px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 1em;
    transition: border-color 0.3s ease;
}

.email-input:focus {
    outline: none;
    border-color: #2c3e50; /* Subtle color change on focus */
    box-shadow: 0 0 10px rgba(44, 62, 80, 0.1);
}

.btn {
    background-color: #28a745;
    color: white;
    padding: 12px 30px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #218838;
}

/* Thank You Message */
.thank-you {
    font-size: 1.2em;
    color: #28a745;
    margin-top: 15px;
}

/* Footer Section */
.footer {
    padding: 20px;
    margin-top: 40px;
    font-size: 0.9em;
    color: #777;
}

.footer p {
    margin-bottom: 10px;
}

.navbar {
    display: none;
}

/* Survey Section */
.survey-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    margin-top: 20px;
    text-align: left; /* Align text to the left */
}

.survey-form .form-group {
    margin-bottom: 20px;
}

.survey-form label {
    font-size: 1em;
    margin-right: 15px; /* Space between the label and the radio button */
    display: inline-block;
    color: #333;
}

.survey-form input[type="radio"] {
    margin-right: 8px; /* Reduce space between radio button and label */
}

.survey-form .form-group div {
    display: inline-block; /* Keep the radio buttons inline with their labels */
    margin-bottom: 10px; /* Slight margin for spacing between options */
}

.survey-form .btn {
    margin-top: 20px;
    background-color: #28a745;
    padding: 10px 20px;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    border: none;
}

/* "How It Works" Section */
.how-it-works-section {
    padding: 40px 20px;
    background-color: #ecf0f1;
    border-radius: 12px;
    margin-top: 20px;
}

.how-it-works-section h2 {
    font-size: 1.75em;
    color: #2c3e50;
    margin-bottom: 20px;
}

.how-it-works-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.how-it-works-item h3 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.how-it-works-item ul {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
    list-style-type: none; /* Removes bullet points */

}

.how-it-works-item li {
    font-size: 1.1em;
    margin: 8px 0;
    line-height: 1.4;
    margin-bottom: 15px; /* Adds space between list items */

}

.country-input {
    width: 40%;
    padding: 10px;
    border: 1px solid #2c3e50; /* Match your theme */
    border-radius: 8px;
    margin-top: 10px;
    max-width: 400px; /* Set a maximum width */
}

.country-suggestions {
    list-style: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #2c3e50; /* Match your theme */
    border-radius: 8px;
    background: white;
    max-height: 200px;
    overflow-y: auto;
}

.country-suggestions li {
    padding: 10px;
    cursor: pointer;
}

.country-suggestions li:hover {
    background-color: #f1f1f1; /* Highlight on hover */
}


