.what-we-do {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
}

.what-we-do h2 {
    font-size: 2.5em;
    margin-bottom: 1rem;
    font-family: Arial, sans-serif;
}

.what-we-do-items {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    max-width: 1200px;
    margin-top: 2rem;
}

.what-we-do-item {
    width: 150px;
    text-align: center;
    font-size: 1.1em;
    color: #333;
}

.what-we-do-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #5cb85c;
}
